.nav-topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  scroll-behavior: smooth;
}

.why {
  scroll-behavior: smooth;
  overflow-y: scroll;
}

body {
  margin: 0;
  font-family: "Lato";
}

h2 {
  font-weight: bold;
  font-size: 60px;
  color: #F9B232;
}

h3 {
  font-size: 18px;
  color: #fff;
}

.subtitle {
  list-style-type: none;
  list-style-image: none;
  font-size: 35px;
  font-weight: bold;
  color: #2A3749;
  margin-bottom: 7%;
}

.uppercase {
  text-transform: uppercase;
}

.position-absolute {
  position: absolute;
}

.main {
  display: inline-block;
  background-size: 100%;
  width: 100%;
  position: relative;
}

.texts {
  padding: 20px;
}

.textsub {
  padding: 20px;
  line-height: 1.6;
}

.stick-left {
  position: absolute;
  left: 0;
}

.margin-top {
  margin-top: 30%;
}

.margin-top-box-why {
  margin-top: 15%;
}

.margin-top-box-func {
  margin-top: 10%;
}

.margin-top-box-image {
  margin-top: 10%;
}

.margin-bottom {
  margin-bottom: 1rem !important;
}

.icon {
  padding-left: 0;
  padding-top: 0;
  padding-right: 4%;
  padding-bottom: 2%;
}

.main-footer {
  text-align: center;
  box-shadow: 0 10px 0px 0px #2a3749;
  margin-bottom: 10px;
}

.bg-nav {
  background: #2A3749;
}

.navbar-nav a {
  font-size: 16px;
  color: white;
  text-decoration: none;
}

.navbar-nav a:hover {
  font-size: 16px;
  color: #F9B232;
}

.wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
}