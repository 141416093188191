.section-cta-form {
  box-shadow: 0 2px 6.65px 0.35px rgba(0, 0, 0, 0.3);
}

.card, .send-second, .section-cta-form, .send {
  border-radius: 5px;
}

.nav-topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  scroll-behavior: smooth;
}

.why {
  scroll-behavior: smooth;
  overflow-y: scroll;
}

body {
  margin: 0;
  font-family: "Lato";
}

h2 {
  font-weight: bold;
  font-size: 60px;
  color: #F9B232;
}

h3 {
  font-size: 18px;
  color: #fff;
}

.subtitle {
  list-style-type: none;
  list-style-image: none;
  font-size: 35px;
  font-weight: bold;
  color: #2A3749;
  margin-bottom: 7%;
}

.uppercase {
  text-transform: uppercase;
}

.position-absolute {
  position: absolute;
}

.main {
  display: inline-block;
  background-size: 100%;
  width: 100%;
  position: relative;
}

.texts {
  padding: 20px;
}

.textsub {
  padding: 20px;
  line-height: 1.6;
}

.stick-left {
  position: absolute;
  left: 0;
}

.margin-top {
  margin-top: 30%;
}

.margin-top-box-why {
  margin-top: 15%;
}

.margin-top-box-func {
  margin-top: 10%;
}

.margin-top-box-image {
  margin-top: 10%;
}

.margin-bottom {
  margin-bottom: 1rem !important;
}

.icon {
  padding-left: 0;
  padding-top: 0;
  padding-right: 4%;
  padding-bottom: 2%;
}

.main-footer {
  text-align: center;
  box-shadow: 0 10px 0px 0px #2a3749;
  margin-bottom: 10px;
}

.bg-nav {
  background: #2A3749;
}

.navbar-nav a {
  font-size: 16px;
  color: white;
  text-decoration: none;
}

.navbar-nav a:hover {
  font-size: 16px;
  color: #F9B232;
}

.wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
}

.section-cta {
  background: url(/images/bg1.png) top no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

form {
  text-align: center;
  margin: 0 auto;
}

form label {
  display: block;
  font-size: 18px;
  color: #4d4d4d;
  margin: 0 0 10px;
}

form input {
  display: block;
  width: 100%;
  margin: 0 0 20px;
  font-size: 18px;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.send {
  background-color: #F9B232;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border: 0;
  transition: 1s all;
  cursor: pointer;
}
.send:hover {
  background-color: #f19d07;
}

.section-cta-form {
  padding: 4rem 3rem 2rem 3rem;
  margin: 20px;
  padding: 40px;
  background-color: #fff;
  line-height: 1.5;
}

.title-cta {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.subtitle-cta {
  text-align: center;
  color: #2A3749;
  font-size: 16px;
  opacity: 80%;
  margin: 2rem 0;
}

.loader {
  border: 16px solid #2A3749;
  /* Light grey */
  border-top: 16px solid #F9B232;
  /* Blue */
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 2s linear infinite;
  margin: auto;
  opacity: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.title-why {
  list-style-type: none;
  list-style-image: none;
  padding: 0;
  margin: 0;
  font-size: 35px;
  font-weight: bold;
  color: #F9B232;
  margin: 0 0 40px;
}

.ul-why {
  padding-right: 40px;
  display: inline-block;
  vertical-align: top;
}
.ul-why span {
  float: left;
}

.li-why {
  color: #737373;
  margin: 0 0 30px;
}
.li-why strong {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  color: #2A3749;
}
.li-why p {
  margin-top: 15px;
  font-size: 18px;
  color: #737373;
}

#fixed {
  overflow-x: hidden;
  overflow-y: hidden;
}

.features {
  display: inline-block;
  width: 100%;
  margin-bottom: 6%;
}

.ul-features {
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
}

.li-features {
  padding: 25px;
  margin-right: 8%;
  color: #676767;
  padding-left: 40px;
  padding-top: 3px;
  background: url("/images/ic_check.png") no-repeat left top;
}

.testimonials {
  background: #ffbc35;
  padding: 5%;
}

.description {
  margin: 2rem;
  font-size: 20px;
  line-height: 1.25;
}

.author {
  font-size: 18px;
  font-style: italic;
  margin-bottom: 0.5%;
}

.carousel-control-next, .carousel-control-prev {
  width: 0;
}

.cta-second {
  padding: 5%;
}

.send-second {
  background-color: #2A3749;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border: 0;
  transition: 1s all;
  cursor: pointer;
}
.send-second:hover {
  background-color: #f19d07;
}

.title-cta-second {
  color: #2A3749;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.subtitle-cta-second {
  text-align: center;
  color: #979797;
  font-size: 18px;
  opacity: 80%;
  margin: 2rem 0;
}

.loaderSecond {
  border: 16px solid #2A3749;
  /* Light grey */
  border-top: 16px solid #F9B232;
  /* Blue */
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spinSecond 2s linear infinite;
  margin: auto;
  opacity: 0;
}

@keyframes spinSecond {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.card {
  border: 0;
  box-shadow: 0 2px 6.65px 0.35px rgba(0, 0, 0, 0.09);
}

.spotlight {
  background: url(images/bg2.13012cc99c625b0fe80a5c1f79407c26.png) top no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 5rem;
  width: 100%;
  padding: 4rem 0;
}

.card-img-top {
  width: 140px;
  height: 140px;
  margin-top: -100px;
  position: absolute;
}

.card-title {
  color: #2A3749;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 1.5;
}

.card-text {
  font-size: 14px;
  text-align: center;
  color: #676767;
  line-height: 1.5;
}

.cases {
  background: url(/images/bg_cases.png) top no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  padding-bottom: 3rem;
}